import React from "react";
import DataTable from "../DataTable";

const ArchivesTable = () => {
  const archivesData = [
    // Data for Trade Archives
  ];

  return (
    <DataTable data={archivesData} isSupplier={false} />
  );
};

export default ArchivesTable;
