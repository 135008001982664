import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useMatch, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// import "./dashboard.scss";
import requestIcon from "../../../assets/request-price2.svg";
import productIcon from "../../../assets/product2.svg";
import archiveIcon from "../../../assets/archive2.svg";
import RequestsTable from "../RequestsTable";
import ProductsTable from "../ProductsTable";
import ArchivesTable from "../ArchivesTable";
import axios from "axios";
import Cookie from "universal-cookie";
import ErrorHandler from "../../ErrorHandler/indes";

const Dashboard = () => {
  const { REACT_APP_URL } = process.env;
  // states
  const [selectedBox, setSelectedBox] = useState(null);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // get token from cookie
    const cookie = new Cookie();
    const authCookie = cookie.get("token");

    const getOrders = async () => {
      try {
        const res = await axios.get(`${REACT_APP_URL}/order/all`, {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        });
        setOrders(res.data.orders);
      } catch (err) {
        console.log(err);
        // fail alert
        setSuccess(false);
        setText("Something went wrong!");
        setAlert(true);
      }
    };
    getOrders();

    const getProducts = async () => {
      try {
        const res = await axios.get(`${REACT_APP_URL}/product/all`);
        setProducts(res.data.products);
      } catch (err) {
        console.log(err);
        // fail alert
        setSuccess(false);
        setText("Something went wrong!");
        setAlert(true);
      }
    };
    getProducts();
  }, []);

  // handlers
  const handleBoxClick = (boxName) => {
    setSelectedBox(boxName);
    navigate(`/${boxName.toLowerCase()}`);
  };
  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <div className="dashboard">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <div className="admin-header">
        <span onClick={handleHomeClick} style={{ cursor: "pointer" }}>
          Home
        </span>{" "}
        / Admin Panel / Dashboard
      </div>
      <div className="dashboard-components">
        <Link to={`requests`}>
          <div
            className={`box ${selectedBox === "Requests" ? "selected" : ""}`}
            onClick={() => handleBoxClick("Requests")}
          >
            <img className="dash-icon" alt="Requests" src={requestIcon} />
            <div>
              <div className="total-num">{orders.length}</div>
              <div>Customers' Requests</div>
            </div>
          </div>
        </Link>
        <Link to={`products`}>
          <div
            className={`box ${selectedBox === "Products" ? "selected" : ""}`}
            onClick={() => handleBoxClick("Products")}
          >
            <img className="dash-icon" alt="Products" src={productIcon} />
            <div>
              <div className="total-num">{products.length}</div>
              <div>Suppliers' Products</div>
            </div>
          </div>
        </Link>
        <Link to={`archives`}>
          <div
            className={`box ${selectedBox === "Archives" ? "selected" : ""}`}
            onClick={() => handleBoxClick("Archives")}
          >
            <img className="dash-icon" alt="Archives" src={archiveIcon} />
            <div>
              <div>Trade Archives</div>
            </div>
          </div>
        </Link>
      </div>
      <div className="data-table">
        <Routes>
          <Route
            path={`requests`}
            element={<RequestsTable requestData={orders} />}
          />
          <Route
            path={`products`}
            element={
              <ProductsTable
                productsData={products}
                setProductsData={setProducts}
              />
            }
          />
          <Route path={`archives`} element={<ArchivesTable />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
