import React from "react";

const DetailsModal = ({ data, selectedRow, onClose, isSupplier }) => {
  const rowData = data[selectedRow];

  return (
    <div className="modal">
      <h2>
        Details of {isSupplier ? "Product" : "Request"} {selectedRow + 1}
      </h2>
      <ul>
        <li>
          <strong>Product : </strong>
          {isSupplier ? rowData.name : rowData.product.name}
        </li>
        <li>
          <strong>Price per one : </strong>${" "}
          {isSupplier ? rowData.price : rowData.product.price.toLocaleString()}
        </li>
        <li>
          <strong>{isSupplier ? "Supplier" : "Customer"} : </strong>
          {isSupplier ? rowData.user.name : rowData.buyer.name}
        </li>
        <li>
          <strong>{isSupplier ? "Supplier" : "Customer"} Email : </strong>
          {isSupplier ? rowData.user.email : rowData.buyer.email}
        </li>
        <li>
          <strong>Amount{!isSupplier && " of Request"} : </strong>
          {isSupplier ? rowData.amount : rowData.quantity}
        </li>
        {!isSupplier && (
          <li>
            <strong>Total Price : </strong>${" "}
            {rowData.totalPrice.toLocaleString()}
          </li>
        )}
        <li>
          <strong>Status : </strong>
          {rowData.isVerified ? "Verified" : "Uncertian"}
        </li>
      </ul>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default DetailsModal;
