import "./errorHandler.scss";

import React, { Fragment } from "react";


const ErrorHandler = ({ text, setAlert, success }) => {
  const AlertHandler = () => {
    setAlert(false);
  };
  return (
    <Fragment>
      <div
        className={`errorHandlerStyle ${success ? "success" : "failed"}`} 
        onClick={AlertHandler}
      >
        {text}
      </div>
    </Fragment>
  );
};

export default ErrorHandler;
