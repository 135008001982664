import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookie from "universal-cookie";
import ErrorHandler from "../../../ErrorHandler/indes";

const EditModal = ({ data, selectedRow, onClose, onSave }) => {
  const { REACT_APP_URL } = process.env;

  // states
  const [editSupplierId, setEditSupplierId] = useState(
    //! editSupplierId will not update immediately
    data[selectedRow].supplierId
  );
  const [suppliers, setSuppliers] = useState([]);
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  useEffect(() => {
    // get token from cookie
    const cookie = new Cookie();
    const authCookie = cookie.get("token");

    const getSuppliers = async () => {
      try {
        const res = await axios.get(`${REACT_APP_URL}/user/`, {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        });
        const chosenSuppliers = res.data?.users.filter((item) =>
          item.products.some(
            (product) =>
              product.name.toLowerCase() ===
                data[selectedRow]?.product.name.toLowerCase() &&
              product.price === data[selectedRow]?.product.price
          )
        );
        if (!Array.isArray(chosenSuppliers)) {
          setSuppliers([chosenSuppliers]);
        } else {
          setSuppliers(chosenSuppliers);
        }
      } catch (err) {
        console.log(err);
        // fail alert
        setSuccess(false);
        setText("Something went wrong!");
        setAlert(true);
      }
    };
    getSuppliers();
  }, []);

  // handlers
  const handleEditChange = (e) => {
    const { value } = e.target;
    setEditSupplierId(value);
  };

  const handleSaveEdit = () => {
    onSave(data[selectedRow].id, editSupplierId, selectedRow);
  };

  return (
    <div className="modal">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <h2>Handle Request {selectedRow + 1}</h2>
      <div>
        <label>Select Supplier:</label>
        <select onChange={handleEditChange} value={editSupplierId || ""}>
          <option value="" disabled>
            Select Supplier
          </option>
          {suppliers.map((supplier, index) => (
            <option key={index} value={supplier.id}>
              {supplier.name} - {supplier.email}
            </option>
          ))}
        </select>
      </div>
      <div className="modal-btn">
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleSaveEdit}>Save</button>
      </div>
    </div>
  );
};

export default EditModal;
