// LoginSignup.js
import React, { useState } from "react";
import "./loginSignup.scss";

import axios from "axios";
import Cookie from "universal-cookie";
import { Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setUserAction } from "../../store/action";
import ErrorHandler from "../ErrorHandler/indes";

const LoginSignup = ({ isAuthenticated, authHandler }) => {
  const { REACT_APP_URL, REACT_APP_TOKEN_EXPIRES_IN } = process.env;
  // states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);

  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  const pathFromStore = useSelector((state) => state.Reducer.path);

  // cookie
  const cookies = new Cookie();
  // initial dispatch
  const dispatch = useDispatch();

  //handlers
  const handleLoginSubmit = async (e) => {
    const date = new Date();
    // prevent default reload
    e.preventDefault();
    // input values
    const user = {
      email: email,
      password: password,
    };
    // request to login
    try {
      var res = await axios.post(`${REACT_APP_URL}/auth/login`, user, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(setUserAction(res.data.user));
      // set token in cookie
      date.setTime(date.getTime() + eval(REACT_APP_TOKEN_EXPIRES_IN));
      cookies.set("token", res.data.token, { expires: date });
      authHandler();
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Wrong Credentials!");
      setAlert(true);
    }
    // Reset form fields
    setName("");
    setEmail("");
    setPassword("");
  };

  //handlers
  const handleSignupSubmit = async (e) => {
    const date = new Date();
    // prevent default reload
    e.preventDefault();
    // input values
    const user = {
      name: name,
      email: email,
      password: password,
    };
    // request to login
    try {
      const res = await axios.post(`${REACT_APP_URL}/auth/signup`, user, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(setUserAction(res.data.user));
      // set token in cookie
      date.setTime(date.getTime() + eval(REACT_APP_TOKEN_EXPIRES_IN));
      cookies.set("token", res.data.token, { expires: date });
      authHandler();
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Wrong Credentials!");
      setAlert(true);
    }
    // Reset form fields
    setName("");
    setEmail("");
    setPassword("");
  };

  // Retrieve the last route after refresh
  if (isAuthenticated) {
    // if (pathFromStore !== "/login-signup") {
    //   return <Navigate to={pathFromStore} />;
    // } else {
    return <Navigate to="/" />;
    // }
  }

  return (
    <div className="login-signup-container">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <form
        className="form-container"
        onSubmit={isLogin ? handleLoginSubmit : handleSignupSubmit}
      >
        <h1>{isLogin ? "Login" : "Sign Up"}</h1>

        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        {!isLogin && (
          <div>
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        )}

        <button type="submit">{isLogin ? "Login" : "Sign Up"}</button>

        <p onClick={() => setIsLogin(!isLogin)}>
          {isLogin ? "Create an account" : "Already have an account? Login"}
        </p>
      </form>
    </div>
  );
};

export default LoginSignup;
