import React, { Fragment, useEffect, useState } from "react";
import "./supplier.scss";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookie from "universal-cookie";

import NavBar from "./NavBar";
import ProductItem from "../Product/ProductItem";
import RequestsTable from "../AdminPanel/RequestsTable";
import NewProduct from "./NewProduct";
import ErrorHandler from "../ErrorHandler/indes";
import logo from "../../assets/mining-company-logo.jpg";
import SupplierSidebar from "./SupplierSidebar";
import EditProductModal from "../AdminPanel/DataTable/EditProductModal";

const Supplier = ({ categories, isAuthenticated }) => {
  const { REACT_APP_URL } = process.env;

  // states
  const [activeTab, setActiveTab] = useState(0);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [supp, setSupp] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);
  const [productIsEditing, setProductIsEditing] = useState(false);
  const [productToEdit, setProductToEdit] = useState({});

  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  // store
  const userFromStore = useSelector((state) => state.Reducer.user);

  const { state } = useLocation();
  const navigate = useNavigate();

  // get token from cookie
  const cookie = new Cookie();
  const authCookie = cookie.get("token");

  useEffect(() => {
    if (state) {
      setSupp(state.supplier);
    }
  }, [state]);

  useEffect(() => {
    const supplierId =
      userFromStore.role === "SUPPLIER" && userFromStore.id === supp.id
        ? userFromStore.id
        : supp.id;

    if (supplierId) {
      const getProducts = async () => {
        try {
          const res = await axios.post(`${REACT_APP_URL}/product/supplier`, {
            supplierId: supplierId,
          });
          setProducts(res.data.products);
        } catch (err) {
          console.log(err);
          // fail alert
          setSuccess(false);
          setText("Something went wrong!");
          setAlert(true);
        }
      };
      getProducts();
    }
    if (isAuthenticated) {
      if (userFromStore.role === "SUPPLIER" && !supp) {
        const getOrders = async () => {
          try {
            const res = await axios.get(`${REACT_APP_URL}/order`, {
              headers: {
                Authorization: `Bearer ${authCookie}`,
              },
            });
            setOrders(res.data.orders);
          } catch (err) {
            console.log(err);
            // fail alert
            setSuccess(false);
            setText("Something went wrong!");
            setAlert(true);
          }
        };
        getOrders();
      }
    }
  }, [supp]);

  // handlers
  const handleTabChange = (index) => {
    setActiveTab(index);
    setShowSidebar(false);
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleEditSubmit = async (editedProduct) => {
    try {
      const res = await axios.put(`${REACT_APP_URL}/product/`, editedProduct, {
        headers: {
          Authorization: `Bearer ${authCookie}`,
        },
      });
      setProducts((products) =>
        products.map((pro) =>
          pro.id === editedProduct.productId
            ? {
                ...pro,
                name: editedProduct.name,
                amount: editedProduct.amount,
                price: editedProduct.price,
              }
            : pro
        )
      );
      // success alert
      setSuccess(true);
      setText("Product updated successfully.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
    closeProductPopup();
  };

  const handleDeleteSubmit = async (product) => {
    try {
      const res = await axios.delete(
        `${REACT_APP_URL}/product/${product.id}`,
        {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        }
      );
      setProducts((products) =>
        products.filter((pro) => pro.id !== product.id)
      );
      // success alert
      setSuccess(true);
      setText("Product deleted successfully.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
  };

  const closeProductPopup = () => {
    setProductIsEditing(false);
  };

  const openProductEditPopup = (product) => {
    setProductIsEditing(true);
    setProductToEdit(product);
  };

  return (
    <div className="supplier-page">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <div className="category-path">
        <span onClick={handleHomeClick} style={{ cursor: "pointer" }}>
          Home
        </span>{" "}
        / Supplier
      </div>
      <div className="img-header w-full">
        {/* <img
          src={img}
          className="w-full mx-auto align-middle h-[340px] rounded-3xl"
        /> */}
      </div>
      <div className="supplier-desc">
        <div className="logo">
          <img src={logo} />
        </div>
        <div className="supplier-info">
          <div>
            <div className="title">
              <div>
                {userFromStore.role === "SUPPLIER" &&
                userFromStore.id === supp.id
                  ? userFromStore.name
                  : supp.name}
              </div>
            </div>
            <div className="info">
              <div>
                {userFromStore.role === "SUPPLIER" &&
                userFromStore.id === supp.id
                  ? userFromStore.email
                  : supp.email}
              </div>
            </div>
          </div>
          <div className="add-btn">
            <button>Contact</button>
          </div>
        </div>
      </div>
      <NavBar
        onTabChange={handleTabChange}
        selectedNavItem={activeTab}
        setSelectedNavItem={setActiveTab}
      >
        <div label="Overview">Content for Overview tab</div>
        {isAuthenticated &&
        window.screen.width <= 700 &&
        userFromStore.role === "SUPPLIER" &&
        userFromStore.id === supp.id ? (
          <div
            className="isActive"
            label={
              activeTab === 2
                ? "My Customers"
                : activeTab === 3
                ? "New Product"
                : activeTab === 1
                ? "Services & Products"
                : "Services & Products"
            }
          ></div>
        ) : (
          <div label="Services & Products">
            Content for Services & Products tab
          </div>
        )}
        {isAuthenticated &&
          window.screen.width > 700 &&
          userFromStore.role === "SUPPLIER" &&
          userFromStore.id === supp.id && (
            <div label="My Customers">Content for About tab</div>
          )}
        {isAuthenticated &&
          window.screen.width > 700 &&
          userFromStore.role === "SUPPLIER" &&
          userFromStore.id === supp.id && (
            <div label="New Product">Content for New Product tab</div>
          )}
      </NavBar>
      {isAuthenticated && window.screen.width <= 700 &&
        userFromStore.role === "SUPPLIER" &&
        userFromStore.id === supp.id && (
          <SupplierSidebar
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            userFromStore={userFromStore}
            supp={supp}
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
        )}
      <div className="content">
        {
          React.Children.toArray([
            <div className="text-justify">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet
              esse molestiae minima voluptate suscipit odit necessitatibus sint,
              deserunt accusantium alias, labore perspiciatis accusamus magnam
              eos sit modi sapiente aperiam distinctio! Lorem ipsum dolor, sit
              amet consectetur adipisicing elit. Quia animi vel ex qui porro
              quidem eum ducimus quasi, voluptatum recusandae facilis? Amet a
              beatae commodi delectus nemo! Possimus, dignissimos autem.
            </div>,
            <div className="product-services">
              {products.map((product, index) => (
                <Fragment key={index}>
                  {productIsEditing &&
                  productToEdit &&
                  productToEdit.id === product.id ? (
                    <EditProductModal
                      key={index}
                      product={productToEdit}
                      onSave={handleEditSubmit}
                      onClose={closeProductPopup}
                    />
                  ) : (
                    <ProductItem
                      key={index}
                      supplierPanel={true}
                      data={product}
                      isAuthenticated={isAuthenticated}
                      handleDeleteSubmit={handleDeleteSubmit}
                      openEditModal={() => openProductEditPopup(product)}
                    />
                  )}
                </Fragment>
              ))}
            </div>,
            <div>
              {isAuthenticated &&
                userFromStore.role === "SUPPLIER" &&
                userFromStore.id === supp.id && (
                  <RequestsTable requestData={orders} />
                )}
            </div>,
            <div>
              {isAuthenticated &&
                userFromStore.role === "SUPPLIER" &&
                userFromStore.id === supp.id && (
                  <NewProduct categories={categories} />
                )}
            </div>,
          ])[activeTab]
        }
      </div>
    </div>
  );
};

export default Supplier;
