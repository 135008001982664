import React, { Fragment, useState } from "react";
import "./productItem.scss";

import img from "../../../assets/minerals.jpg";
import logo from "../../../assets/mining-company-logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import Cookie from "universal-cookie";
import RequestModal from "../RequestModal";
import ErrorHandler from "../../ErrorHandler/indes";

const ProductItem = ({
  supplierPanel,
  data,
  isAuthenticated,
  openEditModal,
  handleDeleteSubmit,
}) => {
  const { REACT_APP_URL } = process.env;
  // console.log(openEditModal);

  // states
  const [showModal, setShowModal] = useState(false);
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  // store
  const userFromStore = useSelector((state) => state.Reducer.user);
  const displaySupplierName = useSelector(
    (state) => state.Reducer.displaySupplier
  );

  const navigate = useNavigate();

  // handlers
  const handleRequestClick = async () => {
    if (!isAuthenticated) {
      navigate("/login-signup");
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // get token from cookie
  const cookie = new Cookie();
  const authCookie = cookie.get("token");

  const handleRequestSubmit = async (requestData) => {
    try {
      const res = await axios.post(`${REACT_APP_URL}/order`, requestData, {
        headers: {
          Authorization: `Bearer ${authCookie}`,
        },
      });
      // success alert
      setSuccess(true);
      setText("Your order has been submitted.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
    setShowModal(false);
  };

  return (
    <div className="product-item">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <Link to="/supplier" state={{ supplier: data?.user }}>
        {!supplierPanel && displaySupplierName && (
          <div className="supplier-name">
            <div className="logo">
              <img src={logo} />
            </div>
            <div>{data?.user.name}</div>
          </div>
        )}
      </Link>
      <div className="product-img">
        <img src={img} />
      </div>
      <div className="product-title">{data?.name}</div>
      <div className="desc">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
        dignissimos molestias soluta laborum impedit ipsam culpa perferendis
        laboriosam animi odit commodi enim tenetur.
      </div>
      <div className="actions">
        <button className="action-btn">$ {data?.price.toLocaleString()}</button>
        {(!isAuthenticated || userFromStore.role === "BUYER") && (
          <button className="request-btn" onClick={handleRequestClick}></button>
        )}
        {isAuthenticated &&
          userFromStore.role === "SUPPLIER" &&
          data?.user.id === userFromStore.id && (
            <div>
              <button
                className="edit-btn"
                onClick={() => openEditModal()}
              ></button>
              <button
                className="delete-btn"
                onClick={() => handleDeleteSubmit(data)}
              ></button>
            </div>
          )}
      </div>
      {showModal && (
        <RequestModal
          isAuthenticated={isAuthenticated}
          onClose={handleCloseModal}
          onRequestSubmit={handleRequestSubmit}
          selectedProduct={data}
        />
      )}
    </div>
  );
};

export default ProductItem;
