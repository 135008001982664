// RequestModal.js
import React, { Fragment, useState } from "react";
import "./requestModal.scss";

const RequestModal = ({
  isAuthenticated,
  onClose,
  onRequestSubmit,
  selectedProduct
}) => {
  // const [selectedProduct, setSelectedProduct] = useState("");
  const [quantity, setQuantity] = useState(1);

  // const handleProductChange = (event) => {
  //   setSelectedProduct(JSON.parse(event.target.value));
  // };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedProduct) {
      //! do not use alert
      alert("Please select a product");
      return;
    }

    onRequestSubmit({
      productId: selectedProduct.id,
      quantity: parseInt(quantity),
      totalPrice: +quantity * +selectedProduct.price,
    });
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        {!isAuthenticated ? (
          <div className="modal-body">Please Login first.</div>
        ) : (
          <Fragment>
            <div className="modal-header">
              <h2>Request for Quotes</h2>
              <button onClick={onClose}>&times;</button>
            </div>
            <div className="modal-body">
              {/* <div>
                <label>Select Product:</label>
                <select onChange={handleProductChange} value={selectedProduct}>
                  <option value="">Select Product</option>
                  {products.map((product, index) => (
                    <option key={index} value={JSON.stringify(product)}>
                      {product.name} - $ {product.price}
                    </option>
                  ))}
                </select>
              </div> */}
              <div>
                <label>Quantity:</label>
                <input
                  type="number"
                  value={quantity}
                  onChange={handleQuantityChange}
                />
              </div>
              <div>
                <label>Total Price:</label>
                <span>
                  {selectedProduct && quantity
                    ? `$ ${+quantity * +selectedProduct.price}`
                    : "-"}
                </span>
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={onClose}>Cancel</button>
              <button onClick={handleSubmit}>Submit</button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default RequestModal;
