import React from "react";
import "./service.scss"

const Service = () => {
  return (
    <div className="w-[80%] mx-auto text-left">
      <div className="text-2xl font-bold mb-5">Our Services</div>
      <div className="services flex flex-row justify-between my-10">
        <div className="sr w-[20%] py-16 bg-[#00497a] mx-auto rounded-2xl text-center screen1100:py-10">
          service number 1
        </div>
        <div className="sr w-[20%] py-16 bg-[#00497a] mx-auto rounded-2xl text-center screen1100:py-10">
          service number 2
        </div>
        <div id="category-sec" className="sr w-[20%] py-16 bg-[#00497a] mx-auto rounded-2xl text-center screen1100:py-10">
          service number 3
        </div>
      </div>
    </div>
  );
};

export default Service;
