import React from "react";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <div className="flex flex-col text-justify">
      <Link to="/">
        <div className="tracking-[0.2em] text-2xl text-[#00497a] font-bold">
          MADAN
        </div>
        <div className="-tracking-wide text-sm text-gray-700 font-[600]">
          TRADE NETWORK
        </div>
      </Link>
    </div>
  );
};

export default Logo;
