import React from "react";
import { Route, Routes } from "react-router-dom";
import "./adminPanel.scss";
import ProductSetting from "./ProductSetting";
import Dashboard from "./Dashboard";

const AdminPanel = ({
  isAuthenticated,
  logoutHandler,
  categories,
  setCategories,
}) => {
  return (
    <div className="admin-panel">
      {isAuthenticated && (
        <Routes>
          <Route exact path={`dashboard/*`} element={<Dashboard />} />
          <Route
            path={`product-setting`}
            element={
              <ProductSetting
                categories={categories}
                setCategories={setCategories}
              />
            }
          />
        </Routes>
      )}
    </div>
  );
};

export default AdminPanel;
