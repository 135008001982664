import React, { Fragment, useEffect, useState } from "react";
import "./dataTable.scss";
import EditModal from "./EditModal";
import DetailsModal from "./DetailsModal";

import axios from "axios";
import Cookie from "universal-cookie";
import { useSelector } from "react-redux";

import sellerIcon from "../../../assets/icons8-seller-50.png";
import infoIcon from "../../../assets/icons8-info.svg";
import verifiedIcon from "../../../assets/icons8-check.svg";
import editIcon from "../../../assets/icons8-edit.svg";
import EditProductModal from "./EditProductModal";
import ErrorHandler from "../../ErrorHandler/indes";

const DataTable = ({ data, setData, isSupplier }) => {
  const { REACT_APP_URL } = process.env;

  // states
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [productIsEditing, setProductIsEditing] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [verificationStatus, setVerificationStatus] = useState(
    data.reduce((acc, item, index) => {
      acc[index] = item.isVerified;
      return acc;
    }, {})
  );
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  // store
  const userFromStore = useSelector((state) => state.Reducer.user);

  useEffect(() => {
    if (isSupplier) {
      // Sort the data based on isVerified and createdAt
      const newSortedData = [...data].sort((a, b) => {
        if (!a.isVerified && b.isVerified) return -1;
        if (a.isVerified && !b.isVerified) return 1;
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setSortedData(newSortedData);

      setVerificationStatus(
        newSortedData.reduce((acc, item, index) => {
          acc[index] = item.isVerified;
          return acc;
        }, {})
      );
    } else {
      setSortedData(data);
    }
  }, [data]);

  const openDetailsPopup = (index) => {
    setSelectedRow(index);
  };

  const openEditPopup = (index) => {
    setSelectedRow(index);
    setIsEditing(true);
  };

  const closePopup = () => {
    setSelectedRow(null);
    setIsEditing(false);
  };

  const openProductEditPopup = (index) => {
    setSelectedRow(index);
    setProductIsEditing(true);
  };

  const closeProductPopup = () => {
    setSelectedRow(null);
    setProductIsEditing(false);
  };

  // get token from cookie
  const cookie = new Cookie();
  const authCookie = cookie.get("token");

  // handlers
  const handleSaveEdit = async (orderId, editedData, index) => {
    try {
      const res = await axios.patch(
        `${REACT_APP_URL}/order/`,
        { orderId: orderId, supplierId: editedData },
        {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        }
      );
      setVerificationStatus((prevStatus) => ({
        ...prevStatus,
        [index]: true,
      }));
      // success alert
      setSuccess(true);
      setText("Order request updated successfully.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
    closePopup();
  };

  const handleProductSaveEdit = async (editedProduct) => {
    try {
      const res = await axios.put(`${REACT_APP_URL}/product/`, editedProduct, {
        headers: {
          Authorization: `Bearer ${authCookie}`,
        },
      });
      //! handle if the request goes wrong the states do not update
      setData((products) =>
        products.map((pro) =>
          pro.id === editedProduct.productId
            ? {
                ...pro,
                name: editedProduct.name,
                amount: editedProduct.amount,
                price: editedProduct.price,
              }
            : pro
        )
      );
      // success alert
      setSuccess(true);
      setText("Product updated successfully.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
    //???????????????
    closePopup();
  };

  const handleVerify = async (productId, index) => {
    try {
      const res = await axios.patch(
        `${REACT_APP_URL}/product`,
        { productId: productId },
        {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        }
      );
      setVerificationStatus((prevStatus) => ({
        ...prevStatus,
        [index]: true,
      }));
      // success alert
      setSuccess(true);
      setText("Product Verified.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
  };

  return (
    <div className="data-table">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <table>
        <thead>
          <tr>
            <th className="index hid-700">Index</th>
            <th>{isSupplier ? "Supplier Name" : "Customer Name"} </th>
            <th>Product</th>
            <th className="hid-700">Amount</th>
            <th className="hid-700">{isSupplier ? "Price" : "Total Price"}</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((item, index) => (
            <tr key={index}>
              <td className="hid-700">{index + 1}</td>
              <td>{isSupplier ? item.user.name : item.buyer.name}</td>
              <td>{isSupplier ? item.name : item.product.name}</td>
              <td className="hid-700">
                {isSupplier ? item.amount : item.quantity}
              </td>
              <td className="hid-700">
                {isSupplier
                  ? item.price.toLocaleString()
                  : item.totalPrice.toLocaleString()}
              </td>
              <td className="details-icons">
                <img src={infoIcon} onClick={() => openDetailsPopup(index)} />
                {userFromStore.role === "ADMIN" &&
                  (isSupplier ? (
                    <Fragment>
                      <img
                        src={editIcon}
                        alt="edit"
                        onClick={() => openProductEditPopup(index)}
                      />
                      {verificationStatus[index] ? (
                        <img src={verifiedIcon} />
                      ) : (
                        <button onClick={() => handleVerify(item.id, index)}>
                          verify
                        </button>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <img
                        src={sellerIcon}
                        onClick={() => openEditPopup(index)}
                      />
                      {verificationStatus[index] && <img src={verifiedIcon} />}
                    </Fragment>
                  ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedRow !== null && (
        <div className="modal-overlay">
          {isEditing ? (
            <EditModal
              data={data}
              selectedRow={selectedRow}
              onClose={closePopup}
              onSave={handleSaveEdit}
            />
          ) : (
            <Fragment>
              {productIsEditing ? (
                <EditProductModal
                  product={sortedData[selectedRow]}
                  onSave={handleProductSaveEdit}
                  onClose={closeProductPopup}
                />
              ) : (
                <DetailsModal
                  data={sortedData}
                  selectedRow={selectedRow}
                  onClose={closePopup}
                  isSupplier={isSupplier}
                />
              )}
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default DataTable;
