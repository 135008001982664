// AddCategoryModal.js
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import "./addCategoryModal.scss";

const AddCategoryModal = ({ isOpen, onClose, onAddCategory }) => {
  const [categoryName, setCategoryName] = useState("");

  // handlers
  const handleEditChange = (e) => {
    const { value } = e.target;
    setCategoryName(value);
  };
  const handleAddCategory = () => {
    onAddCategory(categoryName);
    setCategoryName("");
  };

  return (
    <Fragment>
      {isOpen && (
        <div className="modal-overlay">
          <div className="add-category-modal">
            <h2>Add Category</h2>
            <input
              type="text"
              onChange={handleEditChange}
              value={categoryName || ""}
              placeholder="Category Name"
            />
            <div className="modal-btns">
              <button onClick={onClose}>Cancel</button>
              <button onClick={handleAddCategory}>Add</button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

AddCategoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddCategory: PropTypes.func.isRequired,
};

export default AddCategoryModal;
