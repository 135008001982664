import React, { useState } from "react";

const EditProductModal = ({ product, onSave, onClose }) => {
  const [editedProduct, setEditedProduct] = useState({
    productId: product.id,
    name: product.name,
    amount: product.amount,
    price: product.price,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onSave(editedProduct);
  };

  return (
    <div className="edit-product-modal">
      <h2>Edit Product</h2>
      <label>
        Product Name:
        <input
          type="text"
          name="name"
          value={editedProduct.name}
          onChange={handleChange}
        />
      </label>
      <label>
        Amount:
        <input
          type="number"
          name="amount"
          value={editedProduct.amount}
          onChange={handleChange}
        />
      </label>
      <label>
        Price:
        <input
          type="number"
          name="price"
          value={editedProduct.price}
          onChange={handleChange}
        />
      </label>
      <div className="modal-btn">
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleSubmit}>Save</button>
      </div>
    </div>
  );
};

export default EditProductModal;
