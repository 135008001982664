import React, { Fragment, useEffect, useRef, useState } from "react";
import "./productSetting.scss";

import { useDispatch, useSelector } from "react-redux";
import { setDisplaySupplierName } from "../../../store/action";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookie from "universal-cookie";

import trashIcon from "../../../assets/icons8-trash-24.png";
import editIcon from "../../../assets/icons8-edit.svg";
import saveIcon from "../../../assets/icons8-save-30.png";
import addIcon from "../../../assets/add.svg";
import AddCategoryModal from "./AddCategoryModal";
import ErrorHandler from "../../ErrorHandler/indes";

const ProductSetting = ({ categories, setCategories }) => {
  const { REACT_APP_URL } = process.env;

  // states
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  // Ref for the input element
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const displaySupplierName = useSelector(
    (state) => state.Reducer.displaySupplier
  );

  // Focus on the input field when entering edit mode
  useEffect(() => {
    if (editingCategory && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingCategory]);

  // get token from cookie
  const cookie = new Cookie();
  const authCookie = cookie.get("token");

  // handlers
  const handleSwitchChange = async () => {
    try {
      const res = await axios.post(`${REACT_APP_URL}/config/`,{}, {
        headers: {
          Authorization: `Bearer ${authCookie}`,
        },
      });
      dispatch(setDisplaySupplierName(!displaySupplierName));
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
  };

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleEdit = (category) => {
    setEditingCategory(category);
    setEditedCategoryName(category.title);
  };

  const handleSave = (category) => {
    onEditCategory(category, editedCategoryName);
    setEditingCategory(null);
  };

  const handleDelete = (category) => {
    onDeleteCategory(category.id);
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
  };

  const handleAddCategory = async (categoryName) => {
    try {
      const res = await axios.post(
        `${REACT_APP_URL}/category`,
        { title: categoryName },
        {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        }
      );
      setCategories((categories) => [...categories, res.data.category]);
      // success alert
      setSuccess(true);
      setText("Category added successfully.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
    closeAddModal();
  };

  const onDeleteCategory = async (categoryId) => {
    try {
      const res = await axios.delete(
        `${REACT_APP_URL}/category/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        }
      );
      setCategories((categories) =>
        categories.filter((category) => category.id !== categoryId)
      );
      // success alert
      setSuccess(true);
      setText("Category deleted successfully.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
  };

  const onEditCategory = async (category, editedTitle) => {
    try {
      const res = await axios.patch(
        `${REACT_APP_URL}/category`,
        { categoryId: category.id, title: editedTitle },
        {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        }
      );
      setCategories((categories) =>
        categories.map((cat) =>
          cat.id === category.id ? { ...cat, title: editedTitle } : cat
        )
      );
      // success alert
      setSuccess(true);
      setText("Category updated successfully.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
  };

  return (
    <div className="product-setting">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <div className="admin-header">
        <span onClick={handleHomeClick} style={{ cursor: "pointer" }}>
          Home
        </span>{" "}
        / Admin Panel / Product Setting
      </div>
      <div className="switch-container">
        <div> Display Supplier's Name</div>
        <div className="toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={displaySupplierName}
              onChange={handleSwitchChange}
            />
            <span className="slider"></span>
          </label>
        </div>
      </div>
      <div className="category-editor-container">
        <div className="flex justify-between items-center">
          <div className="cat-title">Categories</div>
          <button className="add-cat" onClick={openAddModal}>
            <img src={addIcon} alt="add" />
          </button>
        </div>
        <div className="category-editor">
          {categories.map((category, index) => (
            <div key={index} className="cat-item">
              {editingCategory === category ? (
                <Fragment>
                  <input
                    type="text"
                    value={editedCategoryName}
                    onChange={(e) => setEditedCategoryName(e.target.value)}
                    ref={inputRef}
                  />
                  <div className="actions">
                    <button
                      onClick={() => handleSave(category)}
                      className="save-btn"
                    >
                      <img src={saveIcon} alt="save" />
                    </button>
                    <button
                      onClick={() => handleDelete(category)}
                      className="delete-btn"
                    >
                      <img src={trashIcon} alt="delete" />
                    </button>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <span>{category.title}</span>
                  <div className="actions category-modal-actions">
                    <button
                      onClick={() => handleEdit(category)}
                      className=""
                    >
                      <img src={editIcon} alt="edit" />
                    </button>
                    <button
                      onClick={() => handleDelete(category)}
                      className=""
                    >
                      <img src={trashIcon} alt="delete" />
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          ))}
        </div>
      </div>
      <AddCategoryModal
        isOpen={showAddModal}
        onClose={closeAddModal}
        onAddCategory={handleAddCategory}
      />
    </div>
  );
};

export default ProductSetting;
