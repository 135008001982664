import "./App.css";

import axios from "axios";
import Cookie from "universal-cookie";
import { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  setPathAction,
  setDisplaySupplierName,
  setUserAction,
} from "./store/action";

import Header from "./components/Header";
import Main from "./components/Main/index";
import Footer from "./components/Footer";
import LoginSignupPage from "./pages/LoginSignupPage";
import ProductPage from "./pages/ProductPage";
import SupplierPage from "./pages/SupplierPage";
import AdminPanelPage from "./pages/AdminPanelPage";
import CustomerProfilePage from "./pages/CustomerProfilePage";
import ErrorHandler from "./components/ErrorHandler/indes";

function App() {
  const { REACT_APP_URL } = process.env;
  // states
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [categories, setCategories] = useState([]);
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  // store
  const pathFromStore = useSelector((state) => state.Reducer.path);
  const userFromStore = useSelector((state) => state.Reducer.user);

  const location = useLocation();
  // initial dispatch
  const dispatch = useDispatch();
  // cookie
  const cookie = new Cookie();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setPathAction(location.pathname));
  }, [location]);

  useEffect(() => {
    // setting authentication cookies
    const authCookie = cookie.get("token");
    authCookie ? authHandler() : logoutHandler();

    const getCategories = async () => {
      try {
        const res = await axios.get(`${REACT_APP_URL}/category/`);
        setCategories(res.data.categories);
      } catch (err) {
        console.log(err);
        // fail alert
        setSuccess(false);
        setText("Something went wrong!");
        setAlert(true);
      }
    };
    getCategories();

    const getDisplaySupplier = async () => {
      try {
        const res = await axios.get(`${REACT_APP_URL}/config/`, {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        });
        dispatch(setDisplaySupplierName(res.data.config.displaySupplier));
      } catch (err) {
        console.log(err);
        // fail alert
        setSuccess(false);
        setText("Something went wrong!");
        setAlert(true);
      }
    };
    getDisplaySupplier();
  }, []);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  const authHandler = () => {
    setIsAuthenticated(true);
  };

  const logoutHandler = () => {
    if (location.pathname !== "/login-signup") {
      setUserAction(null);
      cookie.remove("token", { path: "/" });
      setIsAuthenticated(false);
      cookie.remove("token");
      navigate("/");
    }
  };
  // if the response of api is 401 (unAuthorized) => logout
  axios.interceptors.response.use(
    (response) => {
      // console.log("response", response);
      if (response?.status === 401) {
        // console.log("res 401");
        if (location.pathname !== "/login-signup") {
          logoutHandler();
        }
      }
      return response;
    },
    (error) => {
      // console.log("error", error);
      if (error?.response?.status === 401) {
        // console.log("err 401");
        if (error?.response.data.message !== "Wrong credentials!") {
          logoutHandler();
        }
      }
      if (error?.response && error?.response.data) {
        return Promise.reject(error?.response.data);
      }
      return Promise.reject(error?.message);
    }
  );

  return (
    <div className="App">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <header className="header-container">
        <Header
          isAuthenticated={isAuthenticated}
          logoutHandler={logoutHandler}
        />
      </header>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Main categories={categories} />} />
        <Route
          path="/login-signup"
          element={
            <LoginSignupPage
              isAuthenticated={isAuthenticated}
              authHandler={authHandler}
            />
          }
        />
        <Route
          path="/product/*"
          element={
            <ProductPage
              categories={categories}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/supplier"
          element={
            <SupplierPage
              categories={categories}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/customer/*"
          element={
            <CustomerProfilePage
              isAuthenticated={isAuthenticated}
              logoutHandler={logoutHandler}
            />
          }
        />
        {userFromStore.role === "ADMIN" && (
          <Route
            path="/admin-panel/*"
            element={
              <AdminPanelPage
                categories={categories}
                setCategories={setCategories}
                isAuthenticated={isAuthenticated}
                logoutHandler={logoutHandler}
              />
            }
          />
        )}
      </Routes>
      <footer className="border-b-[10px] border-gray-600 relative">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
