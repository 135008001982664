import React, { Fragment, useState } from "react";
import ProfileSidebar from "../components/CustomerProfile/ProfileSidebar";
import CustomerProfile from "../components/CustomerProfile";

const CustomerProfilePage = ({ isAuthenticated, logoutHandler }) => {
  // states
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <Fragment>
      {/* <ProfileSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} /> */}
      <CustomerProfile
        isAuthenticated={isAuthenticated}
        logoutHandler={logoutHandler}
      />
    </Fragment>
  );
};

export default CustomerProfilePage;
