import React, { useState } from "react";
import "./newProduct.scss";

import axios from "axios";
import Cookie from "universal-cookie";
import ErrorHandler from "../../ErrorHandler/indes";

const NewProduct = ({ categories }) => {
  const { REACT_APP_URL } = process.env;

  // states
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [amount, setAmount] = useState("");
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  const handleNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handlePriceChange = (e) => {
    setProductPrice(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // get token from cookie
    const cookie = new Cookie();
    const authCookie = cookie.get("token");

    const newProductData = {
      name: productName,
      price: productPrice,
      amount: amount,
      categoryId: selectedCategory,
    };

    try {
      const res = await axios.post(`${REACT_APP_URL}/product`, newProductData, {
        headers: {
          Authorization: `Bearer ${authCookie}`,
        },
      });
      // success alert
      setSuccess(true);
      setText("Product has been sent to administrator.");
      setAlert(true);
    } catch (err) {
      console.log(err);
      // fail alert
      setSuccess(false);
      setText("Something went wrong!");
      setAlert(true);
    }
    setAmount("");
    setProductName("");
    setProductPrice("");
    setSelectedCategory("");
  };

  return (
    <div className="NewProduct">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <h2>New Product</h2>
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <label>
            Name:
            <input
              type="text"
              value={productName}
              onChange={handleNameChange}
            />
          </label>
          <label>
            <div>Amount of kg:</div>
            <input
              className="w-6"
              type="number"
              step={1}
              value={amount}
              onChange={handleAmountChange}
              placeholder="0"
            />
          </label>
        </div>
        <br />
        <div className="flex">
          <label>
            Price per 1 kg:
            <input
              type="number"
              step={10000}
              value={productPrice}
              onChange={handlePriceChange}
              placeholder="$"
            />
          </label>
          <br />
          <label>
            Category:
            <select
              value={selectedCategory || ""}
              onChange={handleCategoryChange}
            >
              <option value="" disabled>
                Select Category
              </option>
              {categories?.map((category, index) => (
                <option key={index} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </label>
          <br />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default NewProduct;
