export const setUserAction = (val) => {
  return {
    type: "SET_USER",
    user: val,
  };
};

export const setWalletAction = (val) => {
  return {
    type: "SET_WALLET",
    amount: val,
  };
};

export const setPathAction = (val) => {
  return {
    type: "SET_PATH",
    path: val,
  };
};

export const setDisplaySupplierName = (val) => {
  return {
    type: "SET_DISPLAY_SUPPLIER",
    displaySupplier: val,
  };
};
