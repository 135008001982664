import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import axios from "axios";
import Cookie from "universal-cookie";
import OrderTable from "./OrderTable";
import ErrorHandler from "../../ErrorHandler/indes";

const Profile = () => {
  const { REACT_APP_URL } = process.env;
  // states
  const [orders, setOrders] = useState([]);
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  // store
  const userFromStore = useSelector((state) => state.Reducer.user);

  useEffect(() => {
    // get token from cookie
    const cookie = new Cookie();
    const authCookie = cookie.get("token");

    const getOrders = async () => {
      try {
        const res = await axios.get(`${REACT_APP_URL}/order`, {
          headers: {
            Authorization: `Bearer ${authCookie}`,
          },
        });
        setOrders(res.data.orders);
      } catch (err) {
        console.log(err);
        // fail alert
        setSuccess(false);
        setText("Something went wrong!");
        setAlert(true);
      }
    };
    getOrders();
  }, []);

  return (
    <div className="profile-page">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <div className="admin-header">My Profile </div>
      <div className="profile-container">
        <div className="profile-info">
          <p>
            <strong>Name :</strong> {userFromStore.name}
          </p>
          <p>
            <strong>Email :</strong> {userFromStore.email}
          </p>
          <p>
            <strong>Wallet Balance :</strong> $ {userFromStore.wallet.balance}
          </p>
        </div>
      </div>
      <div className="admin-header">My Orders </div>
      <OrderTable orders={orders} />
    </div>
  );
};

export default Profile;
