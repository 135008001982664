import React from "react";
import "./categories.scss";

import categoryIcon from "../../../assets/list-icon.svg";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
const Category = ({ categories }) => {
  return (
    <div className="w-[80%] mx-auto text-left my-20 relative screen1100:text-sm ">
      <div className="hid-700 w-fit h-fit  absolute right-0 bottom-0 flex flex-row-reverse">
        <img src={categoryIcon} className="w-20 h-fit my-auto" />
        <img src={categoryIcon} className="w-16 h-fit my-auto  " />
        <img src={categoryIcon} className="w-10 h-fit my-auto " />
      </div>
      <div className="text-2xl font-bold mb-5">All Categories</div>
      <ul className="ct grid grid-cols-4 font-bold gap-y-10 gap-x-5 w-full mx-auto ">
        {categories.map((category, index) => (
          <NavLink
            key={index}
            to={{ pathname: "/product" }}
            state={{ category: category.title, categoryId: category.id }}
          >
            <li>{category.title}</li>
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default Category;
