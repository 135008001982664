import React, { Fragment, useEffect, useState } from "react";

import Search from "./Search";
import Navigation from "./Nav";
import Login from "./Login";
import Logo from "./Logo";
import Cookie from "universal-cookie";

import { useSelector } from "react-redux";

import blankProfile from "../../assets/blank-profile-pic.png";
import sortDownIcon from "../../assets/icons8-sort-down-30.png";

import "./header.scss";
import { Link } from "react-router-dom";

const Header = ({ isAuthenticated, logoutHandler }) => {
  // states
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // store
  const userFromStore = useSelector((state) => state.Reducer.user);

  // cookie
  const cookie = new Cookie();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="header bg-gray-100 top-0 m-0 w-[100%] mx-auto flex flex-row justify-between py-4 px-12">
      <Logo />
      <Search />
      <Navigation />
      {isAuthenticated && cookie.get("token") ? (
        <div className="dropdown" onClick={toggleDropdown}>
          <div className="profile-img">
            <img className="profile" alt="profile" src={blankProfile} />
            <div>{userFromStore.name}</div>
            <img className="w-4" src={sortDownIcon} />
          </div>
          {dropdownOpen && (
            <div className="dropdown-content">
              {userFromStore.role === "ADMIN" ? (
                <Link to="/admin-panel/dashboard">Admin Panel</Link>
              ) : userFromStore.role === "SUPPLIER" ? (
                <Link to="/supplier" state={{ supplier: userFromStore }}>My Panel</Link>
              ) : (
                <Link to="/customer/profile">Profile & Cart</Link>
              )}
              <Link to="/" onClick={logoutHandler}>
                Logout
              </Link>
            </div>
          )}
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Header;
