import React from "react";
import DataTable from "../DataTable";

const ProductsTable = ({ productsData, setProductsData }) => {
  return (
    <DataTable
      data={productsData}
      setData={setProductsData}
      isSupplier={true}
    />
  );
};

export default ProductsTable;
