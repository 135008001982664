import React from "react";
import { Route, Routes } from "react-router-dom";
import "./customerProfile.scss";
import Profile from "./Profile";

const CustomerProfile = ({ isAuthenticated, logoutHandler }) => {


  return (
    <div className="customer-profile">
      {isAuthenticated &&
      <Routes>
        <Route exact path={`profile`} element={<Profile />} />
        {/* <Route path={`orders`} element={<ProductSetting />} /> */}
      </Routes>
      }
    </div>
  );
};

export default CustomerProfile;
