import React, { useEffect, useState } from "react";
import "./navbar.scss"; // You can create a separate CSS file for styling

const Navbar = ({
  children,
  onTabChange,
  selectedNavItem,
  setSelectedNavItem,
}) => {
  const childLength = children.filter((item) => item !== false).length;

  const handleNavItemClick = (index) => {
    setSelectedNavItem(index);
    onTabChange(index);
  };

  const handleNavItemHover = (index) => {
    setSelectedNavItem(index);
  };

  return (
    <div className="navbar">
      <ul className={childLength === 4 ? "py" : ""}>
        {React.Children.map(
          children,
          (child, index) =>
            child && (
              <li
                className={
                  selectedNavItem === index && childLength === 4
                    ? "isActive rotate45"
                    : selectedNavItem === index
                    ? "isActive"
                    : index === 1 && window.screen.width <= 700 &&
                      (selectedNavItem === 2 || selectedNavItem === 3)
                    ? "isActive"
                    : childLength === 4
                    ? "rotate45"
                    : ""
                }
                onClick={() => handleNavItemClick(index)}
                // onMouseOver={() => handleNavItemHover(index)}
              >
                {child?.props.label}
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default Navbar;
