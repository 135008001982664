// LoginSignupPage.js
import React, { Fragment } from "react";
import LoginSignup from "../components/LoginSignup";

const LoginSignupPage = ({ isAuthenticated, authHandler }) => {
  return (
    <Fragment>
      <LoginSignup
        isAuthenticated={isAuthenticated}
        authHandler={authHandler}
      />
    </Fragment>
  );
};

export default LoginSignupPage;
