import React from "react";
import "./footer.scss"

const Footer = () => {
  return (
    <div className="bg-gray-100 border-b-[40px] border-[#00497a] relative mt-[25vh]">
      <div className="w-[90%] mx-auto text-left mt-10 mb-6 relative border-t-2 border-t-[#00497a]  ">
        <ul className="ft grid grid-cols-4 font-semibold gap-y-8 w-full  mt-10 mx-10">
          <div>
            <li>About Us</li>
            <li>Compliance</li>
            <li>About IAF</li>
            <li>About IAF Certsearch</li>
            <li>ISO Certification</li>
            <li>Case Studies</li>
          </div>
          <div>
            <li>Benefits</li>
            <li>Sign Up</li>
            <li>Categories</li>
            <li>How it works</li>
            <li>Global sourcing</li>
          </div>
          <div>
            <li>FAQ</li>
            <li>User guide</li>
          </div>
          <div>
            <li>Contact Us</li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
