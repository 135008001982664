import React, { Fragment } from "react";
import Supplier from "../components/Suppplier";

const SupplierPage = ({ categories, isAuthenticated }) => {
  return (
    <Fragment>
      <Supplier categories={categories} isAuthenticated={isAuthenticated} />
    </Fragment>
  );
};

export default SupplierPage;
