const initialState = {
  user: {},
  path: "",
  displaySupplier: false,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_WALLET":
      return {
        ...state,
        user: {
          ...state.user,
          wallet: {
            ...state.user.wallet,
            amount: action.amount,
          },
        },
      };
    case "SET_PATH":
      return {
        ...state,
        path: action.path,
      };
    case "SET_DISPLAY_SUPPLIER":
      return {
        ...state,
        displaySupplier: action.displaySupplier,
      };
    default:
      return state;
  }
};

export default Reducer;
