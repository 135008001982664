import React from "react";
import SlideShow from "./SlideShow";
import AboutUs from "./AboutUs";
import Servics from "./Service";
import Category from "./Categories";
import PopularProduct from "./PopularProducts";

const Main = ({ categories }) => {
  return (
    <div className="main">
      <SlideShow />
      <AboutUs />
      <Servics />
      <Category categories={categories} />
      <PopularProduct />
    </div>
  );
};

export default Main;
