import React from "react";
import searchIcon from "../../../assets/search-icon.png";
import { NavLink } from "react-router-dom";

const Search = () => {
  return (
    <div className="search flex flex-row w-[30%] justify-evenly">
      <NavLink
        className={({ isActive }) => (isActive ? "flex flex-row text-[#00497a]" : "flex flex-row")}
        to="/about-us"
      >
        <div className="my-auto text-[#00497a] font-semibold">About Us</div>
      </NavLink>
      <div className="w-2/3 border-2 py-1 my-auto flex flex-row justify-between">
        <input
          placeholder="Tell Us What You Need"
          className=" pl-3 focus:outline-none h-full w-full text-xs font-semibold text-gray-400 my-auto bg-gray-100"
        />
        <img
          src={searchIcon}
          className="w-6 h-6 my-auto mr-2 screen1000:w-4 screen1000:h-4 "
        />
      </div>
    </div>
  );
};

export default Search;
