import React from "react";
import "./popularProducts.scss"
import p1 from "../../../assets/p1.jpg";
import p2 from "../../../assets/p2.jpg";
import p3 from "../../../assets/p3.jpg";
import p4 from "../../../assets/p4.jpg";
const PopularProducts = () => {
  return (
    <div>
      <div className="w-[80%] mx-auto text-left">
        <div className="text-2xl font-bold mb-5">Popular Products</div>
        <div className="prc flex flex-row justify-between my-10">
          <div className="pr w-[20%] mx-auto rounded-2xl text-center shadow-lg shadow-slate-500">
            <img src={p4} className="rounded-2xl w-full h-full" />
          </div>
          <div className="pr w-[20%] mx-auto rounded-2xl text-center shadow-lg shadow-slate-500">
            <img src={p1} className="rounded-2xl w-full h-full" />
          </div>
          <div className="pr w-[20%] mx-auto rounded-2xl text-center shadow-lg shadow-slate-500">
            <img src={p2} className="rounded-2xl w-full h-full" />
          </div>
          <div className="pr w-[20%] mx-auto rounded-2xl text-center shadow-lg shadow-slate-500">
            <img src={p3} className="rounded-2xl w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularProducts;
