import React, { useState, useEffect } from "react";

import img1 from "../../../assets/7.jpg";
import img2 from "../../../assets/5.jpg";
import img3 from "../../../assets/6.jpg";
import img4 from "../../../assets/7.jpg";

import "./slide-show.css";

const SlideShow = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  const showSlides = (n) => {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      setSlideIndex(1);
    }
    if (n < 1) {
      setSlideIndex(slides.length);
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }

    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
  };

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex, setSlideIndex]);

  const currentSlide = (n) => {
    showSlides(setSlideIndex(n));
  };

  return (
    <div className="slide-show relative my-10">
      <div className="slide-container w-[70%] relative mx-auto">
        <div className="mySlides hidden fade ">
          <div className=" bg-gradient-to-t from-[#00497a] via-transparent  to-transparent w-full h-full absolute z-10 rounded-3xl" />
          <img
            src={img1}
            className="slides w-full mx-auto align-middle h-[340px] rounded-3xl"
          />

          <div className=" flex flex-col bg-transparent  text-white absolute bottom-4 left-5 text-left z-20">
            <span className="font-bold text-xl">Caption One</span>
            <span>Image NO.1</span>
          </div>
        </div>

        <div className="mySlides hidden fade">
          <div className=" bg-gradient-to-t from-[#00497a] via-transparent  to-transparent w-full h-full absolute z-10 rounded-3xl" />
          <img
            src={img2}
            className="slides w-full mx-auto align-middle h-[340px] rounded-3xl"
          />
          <div className=" flex flex-col bg-transparent  text-white absolute bottom-4 left-5 text-left z-20">
            <span className="font-bold text-xl">Caption Two</span>
            <span>Image NO.2</span>
          </div>
        </div>

        <div className="mySlides hidden fade">
          <div className=" bg-gradient-to-t from-[#00497a] via-transparent  to-transparent w-full h-full absolute z-10 rounded-3xl" />
          <img
            src={img3}
            className="slides w-full mx-auto align-middle h-[340px] rounded-3xl"
          />

          <div className=" flex flex-col bg-transparent  text-white absolute bottom-4 left-5 text-left z-20">
            <span className="font-bold text-xl">Caption Three</span>
            <span>Image NO.3</span>
          </div>
        </div>
        <div className="mySlides hidden fade">
          <div className=" bg-gradient-to-t from-[#00497a] via-transparent  to-transparent w-full h-full absolute z-10 rounded-3xl" />
          <img
            src={img4}
            className="slides w-full mx-auto align-middle h-[340px] rounded-3xl"
          />

          <div className=" flex flex-col bg-transparent  text-white absolute bottom-4 left-5 text-left z-20">
            <span className="font-bold text-xl">Caption Four</span>
            <span>Image NO.4</span>
          </div>
        </div>
      </div>

      <div className="dot-container w-[70%] mx-auto  absolute bottom-2 right-12 text-right z-10 inset-x-0   ">
        <span className="dot" onClick={() => currentSlide(1)}></span>
        <span className="dot" onClick={() => currentSlide(2)}></span>
        <span className="dot" onClick={() => currentSlide(3)}></span>
        <span className="dot" onClick={() => currentSlide(4)}></span>
      </div>
    </div>
  );
};

export default SlideShow;
