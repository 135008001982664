import React, { useEffect, useRef } from "react";
import "./supplierSidebar.scss";
import { useSelector } from "react-redux";

const SuppplierSidebar = ({
  activeTab,
  handleTabChange,
  supp,
  showSidebar,
  setShowSidebar,
}) => {
  const sidebarRef = useRef(null);

  // store
  const userFromStore = useSelector((state) => state.Reducer.user);

  useEffect(() => {
    if (window.screen.width <= 700) {
      const handleOutsideClick = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setShowSidebar(false);
        }
      };

      document.addEventListener("click", handleOutsideClick);

      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [setShowSidebar]);

  return (
    <div
      ref={sidebarRef}
      id="menu"
      className={`drop-menu ${window.screen.width <= 700 ? "z1" : ""}`}
    >
      <div
        className={`sidebar
        
        `}
      >
        <div className="sidebar-menu">
          <div className="dropdown">
            <div
              className={`dropdown-btn ${showSidebar ? "active2" : ""}`}
              onClick={() => setShowSidebar(!showSidebar)}
            >
              More
              <i className="arrow"></i>
            </div>
            {showSidebar && (
              <div className="dropdown-content">
                <div
                  className={`menu-item ${activeTab === 1 ? "active2" : ""}`}
                  onClick={() => handleTabChange(1)}
                >
                  Services & Products
                </div>
                {userFromStore.role === "SUPPLIER" &&
                  userFromStore.id === supp.id && (
                    <div
                      className={`menu-item ${
                        activeTab === 2 ? "active2" : ""
                      }`}
                      onClick={() => handleTabChange(2)}
                    >
                      My Customers
                    </div>
                  )}
                {userFromStore.role === "SUPPLIER" &&
                  userFromStore.id === supp.id && (
                    <div
                      className={`menu-item ${
                        activeTab === 3 ? "active2" : ""
                      }`}
                      onClick={() => handleTabChange(3)}
                    >
                      New Product
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuppplierSidebar;
