import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <Link to="/login-signup">
      <div className="flex flex-row text-white my-auto font-semibold">
        <div className="w-fit mx-auto my-auto px-2 py-1 text-sm bg-gray-600">
          Log in
        </div>
        <div className="w-fit mx-auto my-auto px-1 py-1 text-sm bg-[#00497a]">
          Join free
        </div>
      </div>
    </Link>
  );
};

export default Login;
