import "./sidebar.scss";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import cancelIcon from "../../assets/icons8-cancel-30.png";

const Sidebar = ({ categories, showSidebar, setShowSidebar }) => {
  // states
  const [isFixed, setIsFixed] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const offsetTop = document.getElementById("menu")?.offsetTop;

      if (currentScrollPos > offsetTop) {
        setIsFixed(true);
      } else if (currentScrollPos < prevScrollPos) {
        setIsFixed(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    console.log(window.screen.width);
    if (window.screen.width < 1535) {
      setShowSidebar(false);
    }
  }, [window.screen.width]);

  useEffect(() => {
    if (window.screen.width <= 700) {
      const handleOutsideClick = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setShowSidebar(false);
        }
      };

      document.addEventListener("click", handleOutsideClick);

      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [setShowSidebar]);

  return (
    <div
      ref={sidebarRef}
      id="menu"
      className={`menu ${isFixed ? " top-0" : ""}  ${
        window.screen.width <= 700 ? "z1" : ""
      }`}
    >
      <svg
        onClick={() => setShowSidebar(!showSidebar)}
        className="menu-icon z-30  items-center cursor-pointer m-2 mr-0 ml-4 xm:w-[20px] xlg:w-[40px] right-3"
        fill="#00497a"
        viewBox="0 0 100 80"
        width="28"
        height="28"
      >
        <rect width="100" height="13"></rect>
        <rect y="30" width="100" height="12"></rect>
        <rect y="60" width="100" height="13"></rect>
        {/* <img className="menu-icon" src={menuIcon} alt="icon" /> */}
      </svg>

      <div
        className={`bgg bg-grey-100 shadow top-[70px] py-6 left-0 w-[16em] h-screen text-red fixed z-0 ${
          showSidebar
            ? "transition duration-1000 ease-in-out translate-x-0 "
            : "transition duration-1000 ease-in-out translate-x-[-100%]"
        }`}
      >
        <button
          className={` flex text-3xl items-center cursor-pointer z-50
            sm:top-1 ${
              showSidebar
                ? "transition duration-1000 ease-in-out -translate-x-[90%] fixed "
                : "transition duration-1000 ease-in-out translate-x-0 fixed "
            }`}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <img
            className=" w-5 h-5 my-3"
            width={16}
            height={16}
            src={cancelIcon}
            alt="cancelIcon"
          />
        </button>
        <ul
          className="flex flex-col justify-start items-start px-10 text-[16px] text-black  duration-700 font-bold h-[600px]
          md:text-[15px]
          sm:text-[12px] sm:py-3 
          screen345:text-[11px]
          xlg:text-[22px]
          
          "
        >
          <li className="category-title">Categories</li>
          {categories.map((category, index) => (
            <NavLink
              onClick={() =>
                window.screen.width <= 700 && setShowSidebar(!showSidebar)
              }
              className={({ isActive }) =>
                isActive
                  ? "category-item text-[#00497a]"
                  : "category-item text-[#000]"
              }
              key={index}
              to={{ pathname: `/product/${category.title}` }}
              state={{ category: category.title, categoryId: category.id }}
            >
              <li className="">{category.title}</li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
