import React, { Fragment, useEffect, useState } from "react";
import AdminPanel from "../components/AdminPanel";
import AdminSidebar from "../components/AdminPanel/AdminSidebar";

const AdminPanelPage = ({
  isAuthenticated,
  logoutHandler,
  categories,
  setCategories,
}) => {
  // states
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    console.log(window.screen.width);
    if (window.screen.width < 700) {
      setShowSidebar(false);
    }
  }, [window.screen.width]);

  return (
    <Fragment>
      <AdminSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <AdminPanel
        categories={categories}
        isAuthenticated={isAuthenticated}
        logoutHandler={logoutHandler}
        setCategories={setCategories}
      />
    </Fragment>
  );
};

export default AdminPanelPage;
