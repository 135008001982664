import React from "react";
import "./nav.scss"
import { NavLink } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";

const Navigation = () => {
  return (
    <div className="nav my-auto ">
      <ul className=" flex flex-row justify-center text-sm font-semibold text-gray-600 screen1100:text-sm">
        <NavLink
          className={({ isActive }) => (isActive ? "text-[#00497a]" : "")}
          to="/global-trends"
        >
          <li> Global Trends </li>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "text-[00497a]" : "")}
          to="/suppliers"
        >
          <li className="drop-down px-10 screen1100:px-4">For Suppliers</li>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "text-[#00497a]" : "")}
          to="/buyers"
        >
          <li className="">For Buyers</li>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "text-[#00497a]" : "")}
          to="/price"
        >
          <li className="px-10 screen1100:px-4">Pricing</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Navigation;

// <HashLink to="/#rules">
//   <li>For Buyers</li>
// </HashLink>
