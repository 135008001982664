import React, { Fragment, useState } from "react";
import Product from "../components/Product";
import Sidebar from "../components/Sidebar";

const ProductPage = ({ categories, isAuthenticated }) => {
  
  // states
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <Fragment>
      <Sidebar
        categories={categories}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <Product isAuthenticated={isAuthenticated} />
    </Fragment>
  );
};

export default ProductPage;
