import React, { Fragment, useEffect, useState } from "react";
import "./product.scss";
import ProductItem from "./ProductItem";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import ErrorHandler from "../ErrorHandler/indes";

const Product = ({ isAuthenticated }) => {
  const { REACT_APP_URL } = process.env;

  // states
  const [products, setProducts] = useState([]);
  const [uniqueSuppliers, setUniqueSuppliers] = useState([]);
  // error handler states
  const [success, setSuccess] = useState();
  const [alert, setAlert] = useState(false);
  const [text, setText] = useState("");

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await axios.get(
          `${REACT_APP_URL}/product/${state.categoryId}`
        );
        setProducts(res.data.products);
      } catch (err) {
        console.log(err);
        // fail alert
        setSuccess(false);
        setText("Something went wrong!");
        setAlert(true);
      }
    };
    getProducts();
  }, [state.category]);

  useEffect(() => {
    const uniqueSupplierIds = {};

    products.forEach((product) => {
      const supplierId = product.user.id;

      if (!uniqueSupplierIds[supplierId]) {
        uniqueSupplierIds[supplierId] = true;
      }
    });

    const numberOfUniqueSupplierIds = Object.keys(uniqueSupplierIds);
    setUniqueSuppliers(numberOfUniqueSupplierIds);
  }, [products]);

  // handlers
  const handleCategoryClick = () => {
    navigate("/");
    setTimeout(() => {
      const categorySection = document.getElementById("category-sec");
      if (categorySection) {
        categorySection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  const handleHomeClick = () => {
    navigate("/");
  };

  return (
    <div className="product-page">
      {alert ? (
        <ErrorHandler text={text} setAlert={setAlert} success={success} />
      ) : null}
      <div className="category-path">
        <span onClick={handleHomeClick} style={{ cursor: "pointer" }}>
          Home
        </span>{" "}
        /{" "}
        <span onClick={handleCategoryClick} style={{ cursor: "pointer" }}>
          Category
        </span>{" "}
        / {state.category}
      </div>
      <div className="img-header w-full"></div>
      <div className="product-info">
        <div className="description">
          <div className="title-flex">
            <div className="title">{state.category}</div>
          </div>
          <div className="info">
            <div>{products.length} Products</div>
            <div>{uniqueSuppliers?.length} Supplier(s)</div>
            <div>{uniqueSuppliers.length !== 0 ? "1" : "0"} Country</div>
          </div>
        </div>
      </div>
      <div className="product-container">
        <div className="title">Products</div>
        <div className="products">
          {products.map((product, index) => (
            <ProductItem
              key={index}
              supplierPanel={false}
              data={product}
              isAuthenticated={isAuthenticated}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
