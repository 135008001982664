import React from "react";

const OrderTable = ({ orders }) => {
  return (
    <div className="order-table-container">
      <table>
        <thead>
          <tr>
            <th className="hid-700">Index</th>
            <th>Product</th>
            <th>Amount</th>
            <th>Price per Amount</th>
            <th>Total Price</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td className="hid-700">{index + 1}</td>
              <td>{order.product.name}</td>
              <td>{order.quantity}</td>
              <td>{order.product.price}</td>
              <td>{order.totalPrice}</td>
              <td>{order.isVerified ? "Sent to Supplier" : "Not Verified"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
